import { type Ref } from 'vue'

import { type Image } from '@/types/image'

import { type ProductVariant } from '@/types'

export const useProductVariantDetail = (productVariant: Ref<ProductVariant>) => {
  const title = productVariant.value.displayName

  const handle = productVariant.value.product.handle

  const image = computed<Image>(() => {
    return productVariant.value.image as Image
  })

  const imageUrl = computed<string>(() => {
    if (!image.value || !image.value.url) return ''
    return image.value.url
  })

  return {
    title: title,
    handle: handle,
    imageSrc: imageUrl,
    imageAlt: computed<string>(() => {
      if (image.value && image.value.altText) {
        return image.value.altText
      }
      return `Kind & Conscious | ${title}`
    }),
    metafields: computed(() => {})
  }
}
<script setup lang="ts">
  import  { type PropType } from 'vue'
  
  const props = defineProps({
    checkoutUrl: {
      type: String as PropType<string>,
      required: true,
    },
  })
  
  const isRoutingToCheckout = ref(false)
  
  const goToCheckout = () => {
    isRoutingToCheckout.value = true
    window.location.href = props.checkoutUrl
  }
  </script>

<template>
  <div class="relative">
    <button
      type="button"
      class="w-full relative px-6 py-4 border border-transparent text-base font-medium rounded-md text-white bg-blue-700 shadow-sm hover:bg-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-800 md:flex-shrink-0 md:items-center md:w-auto"
      :class="{
        'bg-opacity-50 cursor-not-allowed': isRoutingToCheckout,
        'bg-opacity-100 cursor-pointer': !isRoutingToCheckout,
      }"
      @click="goToCheckout"
      :disabled="isRoutingToCheckout"
    >
      <span
        class="transition-all duration-200 ease-in-out"
        :class="{
          'opacity-100': !isRoutingToCheckout,
          'opacity-0': isRoutingToCheckout,
        }"
      >
        Checkout
      </span>
      <div
        class="absolute inset-0 flex items-center w-full h-full pointer-events-none transition-all duration-500 ease-in-out"
        :class="{
          'opacity-0': !isRoutingToCheckout,
          'opacity-100': isRoutingToCheckout,
        }"
      >
        <svg 
          class="animate-spin mx-auto h-5 w-5 text-white"
          xmlns="http://www.w3.org/2000/svg" 
          fill="none" 
          viewBox="0 0 24 24"
        >
          <circle 
            class="opacity-25" 
            cx="12" 
            cy="12" 
            r="10" 
            stroke="currentColor" 
            stroke-width="4"
          />
          <path 
            class="opacity-75" 
            fill="currentColor" 
            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
          />
        </svg>
      </div>
    </button>
  </div>
</template>